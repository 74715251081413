import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Home = React.lazy(() => import('./views/auth/Login'))
const ResetPassword = React.lazy(() => import('./views/auth/ResetPassword'))
const Jeux = React.lazy(() => import('../src/compnents/pages/Jeux/Jeux'))
const Tirage = React.lazy(() => import('../src/compnents/pages/Tirages/Tirages'))
const Ticket = React.lazy(() => import('../src/compnents/pages/Tickets/Tickets'))
const Transaction = React.lazy(() => import('../src/compnents/pages/Transactions/Transactions'))
const Reclamations = React.lazy(() => import('../src/compnents/pages/Reclamations/Reclamations'))
const Profil = React.lazy(() => import('../src/compnents/pages/Profil/Profil'))
const Mesreclamations = React.lazy(() => import('../src/compnents/pages/MesReclamations/MesReclamations'))
const Footer = React.lazy(() => import('../src/compnents/inc/Footer/Footer'))
const Protected = React.lazy(() => import('../src/compnents/inc/auth/Protected'))
const NoMatch = React.lazy(() => import('../src/compnents/pages/NoMatch/NoMatch'))
const Detailticket = React.lazy(() => import('../src/compnents/pages/Tickets/TicketDetail'))
const Thanks = React.lazy(() => import('../src/compnents/pages/Successpaiement/Thanks'))
const Navbar = React.lazy(() => import('../src/compnents/inc/navbar/navbar'))
const Signup = React.lazy(() => import('../src/compnents/pages/Signup/Signup'))
const Qrcode = React.lazy(() => import('../src/compnents/QrCode/QrCode'))
const Propos = React.lazy(() => import('../src/compnents/pages/Propos/Propos'))

const JeuxExcep = React.lazy(() => import('../src/compnents/pages/JeuxExceptionnel/Excep'))
const Jeuxquotidiens = React.lazy(() => import('../src/compnents/pages/Jeuxrecurrents/Jeuxquotidiens'))
const Jeuxhebdomadaire = React.lazy(() => import('../src/compnents/pages/Jeuxrecurrents/Jeuxhebdomadaire'))
const Jeuxdouble = React.lazy(() => import('../src/compnents/pages/Jeuxdouble/Jeuxdouble'))
const Politique = React.lazy(() => import('../src/compnents/pages/Politique/Politique'))
const Confidentialite = React.lazy(() => import('../src/compnents/pages/Confidentialite/Confidentialite'))

const Maintenance = React.lazy(() => import('../src/compnents/pages/Maintenance/Maintenance'))

const ThanksNon = React.lazy(() => import('../src/compnents/pages/Successpaiement/ThanksNon'))

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
      shouldShowNavbar: true,
    };
  }

  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
    //console.log(window.location.href)zzz
    const shouldShowNavbar = window.location.href !== 'http://localhost:3000/#/signup';
    if(window.location.href !== 'http://localhost:3000/#/signup') {
      this.setState({ shouldShowNavbar: true  });
    }
    else{
      this.setState({ shouldShowNavbar: false  });
    }
    
  }
  
  render() {
    return (

      <BrowserRouter>
        <Suspense fallback={loading}>
        <Routes>
          
        </Routes>
       
          <Routes>
         
          <Route path="/" element={  <div><Navbar/> <Home /><Footer /> </div>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/propos" element={<div><Navbar/><Propos /><Footer /> </div>} />
          <Route path="/jeux" element={<div><Navbar/><Jeux /><Footer /> </div>} />
          <Route path="/tirage" element={<div><Navbar/><Tirage /><Footer /> </div>} />
          
          <Route path="/reclamation" element={<div><Navbar/><Protected isLoggedIn={this.state.isLoggedIn}><Reclamations /></Protected><Footer /> </div>} />
          <Route path="/ticket" element={<div><Navbar/><Protected isLoggedIn={this.state.isLoggedIn}><Ticket /></Protected><Footer /> </div>} />
          <Route path="/transactions" element={<div><Navbar/><Protected isLoggedIn={this.state.isLoggedIn}><Transaction /></Protected><Footer /> </div>} />
          <Route path="/reclamations" element={<div><Navbar/><Protected isLoggedIn={this.state.isLoggedIn}><Mesreclamations /></Protected><Footer /> </div>} />
          <Route path="/profil" element={<div><Navbar/><Protected isLoggedIn={this.state.isLoggedIn}><Profil /></Protected><Footer /> </div>} />
          <Route path="*" element={ <NoMatch />} />
          
          <Route path="/success" element={<div><Navbar/><Protected isLoggedIn={this.state.isLoggedIn}><Thanks /></Protected><Footer /> </div>} />
         
          <Route path="/qrcode" element={<Qrcode />} />
          <Route path="/detailstickets" element={<Detailticket />} />

          <Route path="/jeuxexceptionnels" element={<div><Navbar/><JeuxExcep /><Footer /> </div>} />
          <Route path="/jeuxquotidiens" element={<div><Navbar/><Jeuxquotidiens /><Footer /> </div>} />
          <Route path="/jeuxhebdomadaire" element={<div><Navbar/><Jeuxhebdomadaire /><Footer /> </div>} />
          <Route path="/jeuxdouble" element={<div><Navbar/><Jeuxdouble /><Footer /> </div>} />
          <Route path="/politique" element={<div><Navbar/><Politique /><Footer /> </div>} />
          <Route path="/confidentialite" element={<div><Navbar/><Confidentialite /><Footer /> </div>} />

          <Route path="/success" element={<div><Navbar/><Thanks /><Footer /> </div>} />

          <Route path="/successfull" element={<div><Navbar/><ThanksNon /><Footer /> </div>} />
          
          
          
             {/* 
          <Route path="/" element={<div><Maintenance /></div>} />
          <Route path="*" element={ <NoMatch />} />
           */}
        </Routes>
        </Suspense>
      </BrowserRouter>
     

     
    )
  }
}

export default App
